import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import cx from "classnames";

import OptionsGroup from "../../components/OptionsGroup";
import { yesNoOptions } from "../../../../commons/loan-application-constants";
import { generateScaleOptions } from "../../../../utils/loan-application.utils";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";
import Button from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  marginTop48OnMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "48px",
    },
  },
}));

const LoanInterestRateRaiseConcernStep = ({
  formik,
  handleNextStep,
  handlePrevStep,
}) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();

  return (
    <Box display="flex" flexDirection="column" gridRowGap="48px">
      <Grid container spacing={6} xs={12} sm={6}>
        <OptionsGroup
          title="Are you concerned about rising interest rates?"
          options={yesNoOptions}
          selectedValue={formik.values.loanQuiz.loanInterestRateRaiseConcern}
          onSelect={(value) =>
            formik.setFieldValue("loanQuiz.loanInterestRateRaiseConcern", value)
          }
        />
      </Grid>
      {formik.values.loanQuiz.loanInterestRateRaiseConcern && (
        <Grid container spacing={6} xs={12}>
          <OptionsGroup
            title="On a scale of 1-10 how concerned are you about rising interest rates (1 not concerned, 10 highly concerned)"
            options={generateScaleOptions(1, 10)}
            selectedValue={
              formik.values.loanQuiz.loanInterestRateRaiseConcernScale
            }
            onSelect={(value) =>
              formik.setFieldValue(
                "loanQuiz.loanInterestRateRaiseConcernScale",
                value
              )
            }
          />
        </Grid>
      )}
      <Grid
        container
        xs={12}
        sm={6}
        justifyContent="space-between"
        classes={{
          root: cx(
            stepClasses.navigationButtonsContainer,
            classes.marginTop48OnMobile
          ),
        }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          onClick={handleNextStep}
          disabled={
            formik.values.loanQuiz.loanInterestRateRaiseConcern &&
            formik.values.loanQuiz.loanInterestRateRaiseConcernScale === 0
          }
        >
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default LoanInterestRateRaiseConcernStep;
